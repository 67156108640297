import { defineMessages } from "react-intl";

// Blog Category Urls
defineMessages({
  all: {
    id: "blog.category.all.url",
    description: "Leave blank, do not translate.",
    defaultMessage: "",
  },
  fertility: {
    id: "blog.category.fertility.url",
    description:
      "Blog Category: Fertility url slug. For example: https://www2.peanut-app.io/blog/tag/<fertility>--2 (English characters and dashes only, lowercase, no spaces)",
    defaultMessage: "fertility",
  },
  lifestyle: {
    id: "blog.category.lifestyle.url",
    description:
      "Blog Category: Lifestyle url slug. For example: https://www2.peanut-app.io/blog/tag/<lifestyle>--2 (English characters and dashes only, lowercase, no spaces)",
    defaultMessage: "lifestyle",
  },
  menopause: {
    id: "blog.category.menopause.url",
    description:
      "Blog Category: Menopause url slug. For example: https://www2.peanut-app.io/blog/tag/<menopause>--2 (English characters and dashes only, lowercase, no spaces)",
    defaultMessage: "menopause",
  },
  motherhood: {
    id: "blog.category.motherhood.url",
    description:
      "Blog Category: Motherhood url slug. For example: https://www2.peanut-app.io/blog/tag/<motherhood>--2 (English characters and dashes only, lowercase, no spaces)",
    defaultMessage: "motherhood",
  },
  peanutnews: {
    id: "blog.category.peanutnews.url",
    description:
      "Blog Category: Peanut News url slug. For example: https://www2.peanut-app.io/blog/tag/<from-peanut>--2 (English characters and dashes only, lowercase, no spaces)",
    defaultMessage: "from-peanut",
  },
  peanutpro: {
    id: "blog.category.peanutpro.url",
    description:
      "Blog Category: Peanut Pro url slug. For example: https://www2.peanut-app.io/blog/tag/<peanut-pro>--41 (English characters and dashes only, lowercase, no spaces)",
    defaultMessage: "from-peanut",
  },
  pregnancy: {
    id: "blog.category.pregnancy.url",
    description:
      "Blog Category: Pregnancy url slug. For example: https://www2.peanut-app.io/blog/tag/<pregnancy>--2 (English characters and dashes only, lowercase, no spaces)",
    defaultMessage: "pregnancy",
  },
});

// Blog Category Labels
defineMessages({
  all: {
    id: "blog.category.all.label",
    description: "Blog category title: All Topics.",
    defaultMessage: "All topics",
  },
  fertility: {
    id: "blog.category.fertility.label",
    description: "Blog category title: Fertility.",
    defaultMessage: "Fertility",
  },
  lifestyle: {
    id: "blog.category.lifestyle.label",
    description: "Blog category title: Lifestyle.",
    defaultMessage: "Lifestyle",
  },
  menopause: {
    id: "blog.category.menopause.label",
    description: "Blog category title: Menopause.",
    defaultMessage: "Menopause",
  },
  motherhood: {
    id: "blog.category.motherhood.label",
    description: "Blog category title: Motherhood.",
    defaultMessage: "Motherhood",
  },
  peanutnews: {
    id: "blog.category.peanutnews.label",
    description: "Blog category title: Peanut News.",
    defaultMessage: "Peanut News",
  },
  peanutpro: {
    id: "blog.category.peanutpro.label",
    description: "Blog category title: Peanut Pro",
    defaultMessage: "Peanut Pro",
  },
  pregnancy: {
    id: "blog.category.pregnancy.label",
    description: "Blog category title: Pregnancy.",
    defaultMessage: "Pregnancy",
  },
});

// Blog Category Page Titles
defineMessages({
  all: {
    id: "blog.category.all.pagetitle",
    description: "Blog Page Title for all topics",
    defaultMessage: "The 411 - Peanut",
  },
  fertility: {
    id: "blog.category.fertility.pagetitle",
    description: "Blog Page Title for: Fertility",
    defaultMessage: "The 411: Fertility - Tips & Advice from Peanut",
  },
  lifestyle: {
    id: "blog.category.lifestyle.pagetitle",
    description: "Blog Page Title for: Lifestyle",
    defaultMessage: "The 411: Lifestyle - Tips & Advice from Peanut",
  },
  menopause: {
    id: "blog.category.menopause.pagetitle",
    description: "Blog Page Title for: Menopause",
    defaultMessage: "The 411: Menopause - Tips & Advice from Peanut",
  },
  motherhood: {
    id: "blog.category.motherhood.pagetitle",
    description: "Blog Page Title for: Motherhood",
    defaultMessage: "The 411: Motherhood - Tips & Advice from Peanut",
  },
  peanutnews: {
    id: "blog.category.peanutnews.pagetitle",
    description: "Blog Page Title for: Peanut News",
    defaultMessage: "The 411: Peanut - App News & Updates",
  },
  peanutpro: {
    id: "blog.category.peanutpro.pagetitle",
    description: "Blog Page Title for: Peanut News",
    defaultMessage: "The 411: Peanut - App News & Updates",
  },
  pregnancy: {
    id: "blog.category.pregnancy.pagetitle",
    description: "Blog Page Title for: Pregnancy",
    defaultMessage: "The 411: Pregnancy - Tips & Advice from Peanut",
  },
});

// Blog Topic Meta Descriptions
defineMessages({
  all: {
    id: "blog.category.all.metadescription",
    description: "Blog Page Title for all topics",
    defaultMessage:
      "Get the lowdown on all things fertility, pregnancy, motherhood, and menopause right here on The 411, a blog powered by Peanut.",
  },
  fertility: {
    id: "blog.category.fertility.metadescription",
    description: "Blog Page Meta Description for: Fertility",
    defaultMessage: "Fertility - Tips & Advice from Peanut",
  },
  lifestyle: {
    id: "blog.category.lifestyle.metadescription",
    description: "Blog Page Meta Description for: Lifestyle",
    defaultMessage: "Lifestyle - Tips & Advice from Peanut",
  },
  menopause: {
    id: "blog.category.menopause.metadescription",
    description: "Blog Page Meta Description for: Menopause",
    defaultMessage: "Menopause - Tips & Advice from Peanut",
  },
  motherhood: {
    id: "blog.category.motherhood.metadescription",
    description: "Blog Page Meta Description for: Motherhood",
    defaultMessage:
      "Everything you need to know about motherhood. Expert articles, development guides, parenting tips, and so much more.",
  },
  peanutnews: {
    id: "blog.category.peanutnews.metadescription",
    description: "Blog Page Meta Description for: Peanut News",
    defaultMessage:
      "Everything you need to know about the Peanut app. Feature updates, app news, and handy how-tos.",
  },
  peanutpro: {
    id: "blog.category.peanutpro.metadescription",
    description: "Blog Page Meta Description for: Peanut Pro News",
    defaultMessage:
      "Everything you need to know about Peanut Pros. Feature updates, app news, and handy how-tos.",
  },
  pregnancy: {
    id: "blog.category.pregnancy.metadescription",
    description: "Blog Page Meta Description for: Pregnancy",
    defaultMessage:
      "Everything you need to know about pregnancy. Expert articles, week-by-week guides, baby name inspiration, and so much more.",
  },
});
